<template>
    <div class="examsList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" :rules="searchFormRules" class="demo-form-inline search-form">
                <el-form-item label="试卷列表" prop="examId">
                    <el-select v-model="searchForm.examId" filterable placeholder="请选择" @change="search">
                        <el-option v-for="item in examList" :key="item.exam_id" :label="item.exam_name+'('+ item.exam_id+')'" :value="item.exam_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="专业">
                    <el-select v-model="searchForm.professionId" filterable placeholder="请选择">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in professionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行政班"  prop="hardId" >
                    <el-select v-model="searchForm.hardId" filterable placeholder="请选择">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in hardClassList" :key="item.id" :label="item.name+'('+item.year+')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入学年" >
                    <el-select v-model="searchForm.year" filterable placeholder="请选择" style="max-width: 100px;">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in yearList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="学号、姓名"  @keyup.enter.native='loadStudentList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='search'>查询</el-button>
                </el-form-item>
                <el-form-item>
                    <jsonExcel class="export-excel-wrapper" :data="jsonData" :fields="jsonFields" :beforeGenerate="excelOutput" name="成绩单.xls">
                        <el-button type="danger" size ="mini" icon="search"  :loading="jsonLoading">导出</el-button>
                    </jsonExcel>

                </el-form-item>

            </el-form>
        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>
                <el-table-column prop="my_no" label="学号"  width="150" align='center' ></el-table-column>
                <el-table-column prop="nick_name" label="姓名"  width="150" align='center' ></el-table-column>
                <el-table-column prop="hard_name" label="行政班" align='center' ></el-table-column>
                <el-table-column prop="year" label="入学年" align='center' ></el-table-column>
                <el-table-column prop="profession_name" label="专业" align='center' ></el-table-column>
                <el-table-column prop="score_level" label="得分" :formatter="formatterLevel" align='center' ></el-table-column>
                <el-table-column prop="score" label="成绩分档" :formatter="formatterDang" align='center' ></el-table-column>
                <el-table-column prop="student_score" label="客观题得分" align='center' ></el-table-column>
                <el-table-column prop="score_zg" label="主观题得分" align='center' ></el-table-column>

                <el-table-column prop="operation" align='center' label="操作" width="300">
                    <template slot-scope='scope'>
                        <el-button type="success" icon='edit' size="mini" v-if="scope.row.oss_full_name1 != null" @click='openViewer(scope.row)'>查看原卷</el-button>
                        <el-button type="primary" size="mini" icon="search" @click='openScoring(scope.row)'>查看批阅卷</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        </div>
        <el-image-viewer v-if="showViewer && examImageList" :on-close="closeViewer" :url-list="examImageList" />
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

    export default {
        name: 'examsList',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:false, isShow:false, showViewer: false, jsonLoading: false, selExam: null,
                examList: [], examImageList: [], hardClassList: [], professionList: [], yearList: [], jsonData: [],
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, examId: null, hardId: null, professionId: null, year: null},
                searchFormRules: {
                    examId: [{ required: true, message: '请选择考试', trigger: 'blur' }],
                },
                jsonFields: {
                    "学号": {
                        field: "my_no",
                        callback: value =>{
                            return "&nbsp;" + value;
                        }
                    }, "姓名": "nick_name", "行政班": "hard_name", "入学年": "year", "专业": "profession_name",
                    "得分": {
                        callback: row =>{
                            let score = 0;
                            if(row.student_score && row.student_score > 0)score = row.student_score;
                            if(row.score_zg && row.score_zg > 0) score = score +row.score_zg;
                            return score;
                        }
                    },
                    "成绩分档": {
                        callback: row =>{
                            let score = 0;
                            if(row.student_score && row.student_score > 0) score = row.student_score;
                            if(row.score_zg && row.score_zg > 0) score = score +row.score_zg;
                            if(this.selExam != null) {
                              let totalScore = this.selExam.score
                              let scoreLevel = '不及格';
                              if (score > totalScore * 0.59 && score < totalScore * 0.8) scoreLevel = '中等';
                              if (score >= totalScore * 0.8 && score < totalScore * 0.9) scoreLevel = '良好';
                              if (score >= totalScore * 0.9) scoreLevel = '优秀';
                              return scoreLevel;
                            }
                            let scoreLevel = '不及格';
                            if(score > 59 && score < 80)  scoreLevel = '中等';
                            if(score >= 80 && score < 90)  scoreLevel = '良好';
                            if(score >= 90)  scoreLevel = '优秀';
                            return scoreLevel;
                        }
                    },
                    "客观题得分": "student_score", "主观题得分": "score_zg", "试卷类型": "paper_ab",
                }
            }
        },
        components:{
            Pagination, ElImageViewer
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadExamList();
            this.loadHardClassIdList();
            this.loadYearList();
            this.loadProfessionList();
	   },
        methods: {
            search(){
                let that = this;
                this.$refs.searchForm.validate((valid) => {
                    if(valid){
                      that.selExam = XEUtils.find(that.examList, item => {
                        return item.exam_id == that.searchForm.examId;
                      });
                      that.loadStudentList();
                    }
                });

            },
            excelOutput(){
                this.jsonLoading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    examId: mUtils.searchText(this.searchForm.examId), hardId: mUtils.searchText(this.searchForm.hardId),
                    professionId: mUtils.searchText(this.searchForm.professionId), year: mUtils.searchText(this.searchForm.year),
                    orderBy: "hard.`name`, stu.my_no"};
                let param = { controllerName: 'exam', methodName: '/examScoreList', pageNo: 1, pageSize: 50000, param: search};
                return simpleApi.listWait(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        this.jsonData = list;
                    }
                    this.jsonLoading = false;
                }).catch((error) => {
                    this.jsonLoading = false;
                    console.log("error")
                });
            },
            loadStudentList(){
                this.loading = true;
                this.tableData = [];
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                     examId: mUtils.searchText(this.searchForm.examId), hardId: mUtils.searchText(this.searchForm.hardId),
                    professionId: mUtils.searchText(this.searchForm.professionId), year: mUtils.searchText(this.searchForm.year),
                    orderBy: "hard.`name`, stu.my_no"};
                let param = { controllerName: 'exam', methodName: '/examScoreList', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        this.tableData = list;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            formatterLevel(row, column, cellValue, index){
                let score = 0;
                if(row.student_score && row.student_score > 0){
                    score = row.student_score;
                }
                if(row.score_zg && row.score_zg > 0){
                    score = score +row.score_zg;
                }
                return score;
            },
            formatterDang(row, column, cellValue, index){
                let score = 0;
                if(row.student_score && row.student_score > 0){
                    score = row.student_score;
                }
                if(row.score_zg && row.score_zg > 0){
                    score = score +row.score_zg;
                }
                if(this.selExam != null){
                  let totalScore = this.selExam.score
                  let scoreLevel = '不及格';
                  if(score > totalScore*0.59 && score < totalScore*0.8)  scoreLevel = '中等';
                  if(score >= totalScore*0.8 && score < totalScore*0.9)  scoreLevel = '良好';
                  if(score >= totalScore*0.9)  scoreLevel = '优秀';
                  return scoreLevel;
                }else{
                  let scoreLevel = '不及格';
                  if(score > 59 && score < 80)  scoreLevel = '中等';
                  if(score >= 80 && score < 90)  scoreLevel = '良好';
                  if(score >= 90)  scoreLevel = '优秀';
                  return scoreLevel;
                }
            },
            loadExamList(){

                let search = { licenceId: this.userInfo.licenceId, schoolId: null, teacherId: null};
                let role = this.userInfo.role;
                if(role == "sclAdmin") search["schoolId"] = this.userInfo.schoolId;
                if(role == "teacher") {
                    search["schoolId"] = this.userInfo.schoolId;
                    search["teacherId"] = this.userInfo.id;
                }
                let param = { controllerName: 'score/ctrl', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.examList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadHardClassIdList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclHardClass', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.hardClassList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadProfessionList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclProfession', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.professionList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadYearList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-7); i<(year+2); i++){
                    this.yearList.unshift(""+i);
                }
            },
            openViewer(row){
                console.log(row)
                this.examImageList = [];
                if(row.oss_full_name1 != null){
                    this.examImageList.push(row.oss_full_name1);
                }
                if(row.oss_full_name2 != null){
                    this.examImageList.push(row.oss_full_name2);
                }
                this.showViewer = true;
            },
            openScoring(item){
                let routeData = this.$router.resolve({name: "scanPreResult", query: {e: this.searchForm.examId, sn: item.my_no}});
                window.open(routeData.href, '_blank');
            },
            closeViewer(){
                this.showViewer = false;
            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadStudentList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadStudentList()
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


